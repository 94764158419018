<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <h1 class="h3">Add Correlation Strength Analysis</h1>
      </div>
      <div class="col-md-4">
        <select v-model="correlation_type" class="form-control">
          <option value="5-min">5 Minutes</option>
          <option value="15-min">15 Minutes</option>
          <option value="1-hour">1 Hour</option>
          <option value="4-hour">4 Hour</option>
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="6-month">6 Months</option>
          <option value="12-month">12 Months</option>
        </select>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="save">
          <div class="row justify-content-between">
            <div class="col-md-5">
              <div class="form-group row">
                <label for="staticEmail" class="col-sm-2 col-form-label">AUD</label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="aud">
                    <option v-for="i in possible" :key="`aud-${i}`" :value="i">{{ i }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group row">
                <label for="staticEmail" class="col-sm-2 col-form-label">CAD</label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="cad">
                    <option v-for="i in possible" :key="`cad-${i}`" :value="i">{{ i }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group row">
                <label for="staticEmail" class="col-sm-2 col-form-label">CHF</label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="chf">
                    <option v-for="i in possible" :key="`chf-${i}`" :value="i">{{ i }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group row">
                <label for="staticEmail" class="col-sm-2 col-form-label">EUR</label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="eur">
                    <option v-for="i in possible" :key="`eur-${i}`" :value="i">{{ i }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group row">
                <label for="staticEmail" class="col-sm-2 col-form-label">GBP</label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="gbp">
                    <option v-for="i in possible" :key="`gbp-${i}`" :value="i">{{ i }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group row">
                <label for="staticEmail" class="col-sm-2 col-form-label">NZD</label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="nzd">
                    <option v-for="i in possible" :key="`nzd-${i}`" :value="i">{{ i }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group row">
                <label for="staticEmail" class="col-sm-2 col-form-label">JPY</label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="jpy">
                    <option v-for="i in possible" :key="`jpy-${i}`" :value="i">{{ i }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group row">
                <label for="staticEmail" class="col-sm-2 col-form-label">USD</label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="usd">
                    <option v-for="i in possible" :key="`usd-${i}`" :value="i">{{ i }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <div class="form-group">
                <button class="btn btn-primary"><i class="fa fa-save mr-2"></i>Save</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      correlation_type: '1-hour',
      possible: [-7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7],
      aud: -7,
      cad: -7,
      chf: -7,
      eur: -7,
      gbp: -7,
      nzd: -7,
      jpy: -7,
      usd: -7
    }
  },

  methods: {
    save() {
      let data = {
        type: this.correlation_type,
        currencies: {
          aud: this.aud,
          cad: this.cad,
          chf: this.chf,
          eur: this.eur,
          gbp: this.gbp,
          nzd: this.nzd,
          jpy: this.jpy,
          usd: this.usd
        }
      }

      this.$loader.start()
      this.$axios.post('/api/v1/admin/correlation-direct', data).then(() => {
        this.$router.push({ name: 'admin.correlation.index' })
        this.$toasted.show('Correlation data has been saved', { duration: 5000 })
        this.$loader.stop()
      })
    }
  },
}
</script>